
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import { Field, Form } from "vee-validate";
import { object, string } from "yup";
import duesApi from "@/core/services/DuesApi";
import membersApi from "@/core/services/MembersApi";
import { ElMessageBox, ElMessage } from "element-plus";
import Filters from "./crafted/widgets/Filters.vue";
import FilterTags from "./crafted/widgets/FilterTags.vue";

export default defineComponent({
    name: "Dues",
    components: { Field, Form, Filters, FilterTags },
    data() {
        return {
            refresh: 0,
            tagsKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: false,
                bailliage: true,
                commander: false,
                country: false,
                dues: false,
                function: false,
                gender: false,
                grade: false,
                medal: false,
                member_type: false,
                other_association: false,
                period: false,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: false,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_actual_balance: false,
                biller_initial_balance: false,
            },
            relevantYear: "",
            loading: true,
            dueSearch: "",
            periodFrom: "",
            periodTo: "",
            selectedValue: "",
            filterGradeSearch: "",
            dueTypes: [] as any,
            priceCategories: [] as any,
            perimeters: [
                {
                    label: "CDR",
                    value: "CDR",
                },
                {
                    label: "OMGD",
                    value: "OMGD",
                },
            ],
            dues: [] as any,
            multipleDue: {
                type: "",
                perimeter: "",
                price_category: "",
                relevant_year: "",
                date: "",
                penalty: false,
                members: [] as any,
            },
            dueAmount: "",
            dueLabel: "",
            autocompleteMember: "",
            duesToAdd: [
                {
                    member: {
                        id: "",
                        name: "",
                    },
                    national_bailliage: "",
                    provincial_bailliage: "",
                    regional_bailliage: "",
                    perimeter: "",
                    type: "",
                    price_category: "",
                    amount: "",
                    date: "",
                },
            ],
            pages: [] as any,
            currentPage: 1,
            duesCount: 0,
            totalPages: 0,
            selectedDues: [] as any,
            actualFilter: "",
            actualCol: "",
            duesTh: [
                { label: "Type", class: "min-w-60px", colName: "type" },
                { label: "ID", class: "min-w-60px", colName: "id" },
                { label: "Name", class: "min-w-60px", colName: "lastname" },
                {
                    label: "Firstname",
                    class: "min-w-60px",
                    colName: "firstname",
                },
                {
                    label: "Bailliage",
                    class: "min-w-60px",
                    colName: "bailliage",
                },
                { label: "Amount", class: "min-w-60px", colName: "amount" },
                {
                    label: "Relevant Year",
                    class: "min-w-60px",
                    colName: "relevant_year",
                },
                {
                    label: "Member",
                    class: "min-w-60px",
                    colName: "is_pro_omgd",
                },
                { label: "Date", class: "min-w-60px", colName: "date" },
            ],
            remoteMembers: [] as any,
            index: 0,
            perm_pos: "",
            year: new Date().getFullYear(),
            disablePC: false,
            disablePerimeter: false,
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const addDuesModalRef = ref<null | HTMLElement>(null) as any;

        const router = useRouter();

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Dues", []);
        });

        const duesValidationSchema = object().shape({
            dueRelevantYear: string()
                .required()
                .label("Relevant year")
                .nullable(),
            ["dueMember0"]: string().required().label("Due member").nullable(),
            ["duePerimeter0"]: string()
                .required()
                .label("Due perimeter")
                .nullable(),
            ["dueType0"]: string().required().label("Due type").nullable(),
            ["duePriceCategory0"]: string()
                .required()
                .label("Due price category")
                .nullable(),
            ["dueDate0"]: string().required().label("Due date").nullable(),
        });
        const addDuesSchema = object().shape({
            type: string().required().label("Type").nullable(),
            perimeter: string().required().label("Perimeter").nullable(),
            price_category: string()
                .required()
                .label("Price category")
                .nullable(),
            relevant_year: string()
                .required()
                .label("Relevant year")
                .nullable(),
            date: string().required().label("Date").nullable(),
        });

        const closeModal = () => {
            hideModal(addDuesModalRef.value);
        };

        return {
            resultsPerPage,
            addDuesModalRef,
            closeModal,
            duesValidationSchema,
            addDuesSchema,
            router,
            debounce: createDebounce(),
        };
    },
    mounted() {
        this.loading = false;
        this.getDues();
        this.getDuesInfos();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
        if (this.perm_pos === "2") {
            this.relevantYear = new Date().getFullYear().toString();
        }
        const today = new Date();
        const day = String(today.getDate()).padStart(2, "0");
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const year = today.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        this.multipleDue.date = formattedDate;
    },
    methods: {
        checkDuesType(code: any) {
            if (this.perm_pos !== "0" && code === "ADM") {
                this.multipleDue.perimeter = "OMGD";
                this.disablePerimeter = true;
            } else {
                this.multipleDue.perimeter = "";
                this.disablePerimeter = false;
            }
        },
        filterPriceCat(e: any) {
            const getPC = new Promise((resolve) => {
                membersApi
                    .getPriceCategories({ p: 1, presult: 9999 })
                    .then((res) => {
                        this.priceCategories = res.data.object_list;
                        this.priceCategories.sort((a: any, b: any) =>
                            a.amount > b.amount ? 1 : -1
                        );
                        resolve(this.priceCategories);
                    });
            });

            getPC.then(() => {
                if (e === "CDR") {
                    if (this.perm_pos === "2") {
                        this.priceCategories = this.priceCategories.filter(
                            (priceCat: any) =>
                                priceCat.code === "Young" ||
                                priceCat.code === "Spouse" ||
                                priceCat.code === "Full"
                        );
                        this.disablePC = false;
                        this.multipleDue.price_category = "";
                    } else if (this.perm_pos === "0") {
                        this.priceCategories = this.priceCategories.filter(
                            (priceCat: any) =>
                                priceCat.code === "Young" ||
                                priceCat.code === "Spouse" ||
                                priceCat.code === "Full" ||
                                priceCat.code === "Free"
                        );
                        this.disablePC = false;
                        this.multipleDue.price_category = "";
                    }
                } else {
                    if (this.perm_pos === "2") {
                        this.priceCategories = this.priceCategories.filter(
                            (priceCat: any) => priceCat.code === "Full OMGD"
                        );
                        this.multipleDue.price_category =
                            this.priceCategories[0].id;
                        this.setDueAmount(this.priceCategories[0].id);
                        this.disablePC = true;
                    } else if (this.perm_pos === "0") {
                        this.priceCategories = this.priceCategories.filter(
                            (priceCat: any) =>
                                priceCat.code === "Full OMGD" ||
                                priceCat.code === "Free"
                        );
                        this.disablePC = false;
                        this.multipleDue.price_category = "";
                    }
                }
            });
        },
        disabledYear(time) {
            const year = new Date(time).getFullYear();
            if (this.perm_pos === "0") {
                const currentYear = new Date().getFullYear();
                const nextYear = currentYear + 1;
                return year !== currentYear && year !== nextYear;
            } else if (this.perm_pos === "2") {
                const today = new Date();
                const month = today.getMonth() + 1;
                if (month >= 1 && month <= 8) {
                    const currentYear = new Date().getFullYear();
                    return year !== currentYear;
                } else if (month >= 9 && month <= 12) {
                    const nextYear = new Date().getFullYear() + 1;
                    return year !== nextYear;
                }
            }
        },

        removeMember(id: any) {
            this.multipleDue.members = this.multipleDue.members.filter(
                (member: any) => member.id !== id
            );
        },
        handleSizeChange() {
            this.getDues();
        },
        applyFilters() {
            var closeFilters = document.getElementById("filter") as any;
            closeFilters.click();
            this.getDues();
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        addDues() {
            if (this.multipleDue.members.length === 0) {
                ElMessage({
                    type: "error",
                    message: "Please select at least one member",
                });
            } else {
                var payload = {} as any;

                payload = {
                    ...this.multipleDue,
                    members: this.multipleDue.members.map(
                        (member: any) => member.id
                    ),
                };

                duesApi.addDues(payload).then((res: any) => {
                    if (res.success) {
                        this.multipleDue = {} as any;
                        this.getDues();
                        this.closeModal();
                        ElMessage({
                            type: "success",
                            message: "Dues added successfully",
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "Something went wrong",
                        });
                    }
                });
            }

            // var finalDues = [] as any;
            // this.duesToAdd.map((due: any) => {
            //     finalDues.push({
            //         member: due.member.id,
            //         relevant_year: this.relevantYear,
            //         perimeter: due.perimeter,
            //         type: due.type,
            //         price_category: due.price_category,
            //         date: due.date,
            //         national_bailliage: due.national_bailliage.id,
            //         provincial_bailliage: due.provincial_bailliage.id,
            //         regional_bailliage: due.regional_bailliage.id,
            //     });
            // });
            // duesApi.addDues(finalDues).then((res: any) => {
            //     if (res.success) {
            //         this.relevantYear = "";
            //         this.duesToAdd = [
            //             {
            //                 member: {
            //                     id: "",
            //                     name: "",
            //                 },
            //                 national_bailliage: "",
            //                 provincial_bailliage: "",
            //                 regional_bailliage: "",
            //                 perimeter: "",
            //                 type: "",
            //                 price_category: "",
            //                 amount: "",
            //                 date: "",
            //             },
            //         ];
            //         this.duesValidationSchema = object().shape({
            //             dueRelevantYear: string().required().label("Relevant year").nullable(),
            //             ["dueMember0"]: string().required().label("Due member").nullable(),
            //             ["duePerimeter0"]: string().required().label("Due perimeter").nullable(),
            //             ["dueType0"]: string().required().label("Due type").nullable(),
            //             ["duePriceCategory0"]: string().required().label("Due price category").nullable(),
            //             ["dueDate0"]: string().required().label("Due date").nullable(),
            //         });
            //         this.closeModal();
            //         ElMessage({
            //             type: "success",
            //             message: "Successfully added dues!",
            //         });
            //         this.getDues();
            //     } else if (res.status === 409) {
            //         ElMessage({
            //             type: "error",
            //             message: "At least one member has already a due at this year.",
            //             duration: 5000,
            //         });
            //     } else {
            //         ElMessage({
            //             type: "error",
            //             message: "An error occured while adding dues.",
            //         });
            //     }
            // });
        },
        editDue(memberId: any, dueId: any, newTab?: boolean) {
            if (newTab) {
                const routeData = this.router.resolve({
                    name: "member-details",
                    params: { member_id: memberId, editDueId: dueId },
                });
                window.open(routeData.href, "_blank");
            } else {
                this.router.push({
                    name: "member-details",
                    params: { member_id: memberId, editDueId: dueId },
                });
            }
        },
        deleteDues(id?: any) {
            var duesIdToDelete = [] as any;

            // If single delete
            if (id) {
                duesIdToDelete.push(id);
                this.loading = true;
                duesApi.deleteDues(duesIdToDelete).then(() => {
                    this.selectedDues = [];
                    var topCheckbox = document.getElementById(
                        "topCheckbox"
                    ) as any;
                    topCheckbox.checked = false;
                    this.currentPage = 1;
                    this.loading = false;
                    ElMessage({
                        type: "info",
                        message: "Due deleted.",
                    });
                    this.getDues();
                });
                // Else if multiple delete
            } else {
                this.selectedDues.map((due: any) => {
                    duesIdToDelete.push(due.id);
                });
                ElMessageBox.confirm(
                    "You have requested the deletion of multiple dues.<br>Would you like to confirm this choice?",
                    "Confirmation",
                    {
                        confirmButtonText: "Confirm",
                        cancelButtonText: "Cancel",
                        customClass: "custom-modal",
                        cancelButtonClass: "cancel-modal",
                        dangerouslyUseHTMLString: true,
                    }
                )
                    .then(() => {
                        this.loading = true;
                        duesApi.deleteDues(duesIdToDelete).then(() => {
                            this.selectedDues = [];
                            // If we delete all the entries on the last page, we need to go back to the previous page
                            if (
                                this.currentPage === this.totalPages &&
                                this.selectedDues.length === this.dues.length
                            ) {
                                this.currentPage = this.currentPage - 1;
                            }
                            var topCheckbox = document.getElementById(
                                "topCheckbox"
                            ) as any;
                            topCheckbox.checked = false;
                            this.currentPage = 1;
                            this.loading = false;
                            ElMessage({
                                type: "info",
                                message: "Dues deleted.",
                            });
                            this.getDues();
                        });
                    })
                    .catch(() => {
                        return; // Cancel
                    });
            }
        },
        loadRemoteMembers(query) {
            return new Promise((resolve) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    const payload = {
                        type: this.multipleDue.type,
                        price_category: this.multipleDue.price_category,
                        perimeter: this.multipleDue.perimeter,
                        relevant_year: this.multipleDue.relevant_year,
                        date: this.multipleDue.date,
                        query: query,
                    };
                    membersApi.listRemoteMultiple(payload).then((response) => {
                        if (response.success) {
                            response.data.map((remoteMember) => {
                                this.remoteMembers.push({
                                    id: remoteMember.id,
                                    firstname:
                                        remoteMember.userprofile.firstname,
                                    lastname: remoteMember.userprofile.lastname,
                                    code: remoteMember.code,
                                });
                            });
                            resolve("");
                        } else {
                            console.log("ERROR");
                        }
                    });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        setDueAmount(e: any) {
            this.priceCategories.map((item: any) => {
                if (item.id === e) {
                    this.dueAmount = item.amount;
                }
            });
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);
            });
        },
        handleSelect(item: any) {
            var alreadyInList = false;

            if (this.multipleDue.members.length === 0)
                this.multipleDue.members.push(item);
            else {
                this.multipleDue.members.map((member: any) => {
                    if (member.id === item.id) alreadyInList = true;
                });
                if (!alreadyInList) this.multipleDue.members.push(item);
                else
                    ElMessage({
                        type: "error",
                        message: "This member is already in the list.",
                    });
            }

            this.multipleDue.members.sort((a: any, b: any) => {
                return a.lastname.localeCompare(b.lastname);
            });

            this.remoteMembers = [];
        },
        addLine() {
            this.duesToAdd.push({
                member: {
                    id: "",
                    name: "",
                },
                national_bailliage: "",
                provincial_bailliage: "",
                regional_bailliage: "",
                perimeter: "",
                type: "",
                price_category: "",
                amount: "",
                date: "",
            });

            const nexValidationSchema = object().shape({
                ["dueMember" + (this.duesToAdd.length - 1)]: string()
                    .required()
                    .label("Due member")
                    .nullable(),
                ["duePerimeter" + (this.duesToAdd.length - 1)]: string()
                    .required()
                    .label("Due perimeter")
                    .nullable(),
                ["dueType" + (this.duesToAdd.length - 1)]: string()
                    .required()
                    .label("Due type")
                    .nullable(),
                ["duePriceCategory" + (this.duesToAdd.length - 1)]: string()
                    .required()
                    .label("Due price category")
                    .nullable(),
                ["dueDate" + (this.duesToAdd.length - 1)]: string()
                    .required()
                    .label("Due date")
                    .nullable(),
            });

            this.duesValidationSchema =
                this.duesValidationSchema.concat(nexValidationSchema);
            this.refresh++;
        },
        removeLine(i: number) {
            this.duesToAdd.splice(i, 1);
            this.duesValidationSchema = this.duesValidationSchema.omit([
                "dueMember" + this.duesToAdd.length,
                "duePerimeter" + this.duesToAdd.length,
                "dueType" + this.duesToAdd.length,
                "duePriceCategory" + this.duesToAdd.length,
                "dueDate" + this.duesToAdd.length,
            ]);
            this.refresh++;
        },
        getDues() {
            this.loading = true;
            this.selectedDues = [];
            this.dues = [];
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        filters: this.filtersPayload,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        filters: this.filtersPayload,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                };
            }

            if (this.dueSearch) {
                payload = { ...payload, query: this.dueSearch };
            }

            duesApi.getDues(payload).then((res) => {
                res.data.cotisation_list.map((due: any) => {
                    this.dues.push(due);
                });
                this.duesCount = res.data.cotisation_count;
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        getDuesInfos() {
            membersApi.getDuesTypes({ p: 1, presult: 9999 }).then((res) => {
                this.dueTypes = res.data.object_list;
                this.dueTypes = this.dueTypes.filter(
                    (item: any) => item.code !== "REIN"
                );
            });
            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.priceCategories = res.data.object_list;
                    this.priceCategories.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getDues();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            this.selectedDues = [];
            var topCheckbox = document.getElementById("topCheckbox") as any;
            topCheckbox.checked = false;

            this.currentPage = pageNumber;
            this.getDues();
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(
                document.getElementsByClassName("custom-checkbox-dues") as any
            );

            this.selectedDues = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.dues.map((due: any) => {
                    this.selectedDues.push(due);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedDues = [];
            }
        },
        handleActionSelect(due: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox" + due.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedDues.push(due);
            } else {
                this.selectedDues.map((user: any) => {
                    if (user.id === due.id) {
                        this.selectedDues.splice(
                            this.selectedDues.indexOf(user),
                            1
                        );
                    }
                });
            }
        },
        getBadgeType(type: string, scope: string) {
            if (scope === "color") {
                switch (type) {
                    case "Due":
                        return "badge-due";
                    case "new_pro_member":
                        return "badge-pro-member";
                    case "Election":
                        return "badge-election";
                    case "transfer":
                        return "badge-transfer";
                    case "new_member":
                        return "badge-new-member";
                    case "Nomination":
                        return "badge-pro-status";
                    case "Promotion":
                        return "badge-promotion";
                    case "addresses":
                        return "badge-addresses";
                    case "Reinstatement":
                        return "badge-reinstatement";
                    case "Admission":
                        return "badge-admission";
                    case "Penalty":
                        return "badge-penalty";
                    case "Renewal":
                        return "badge-renewal";
                    case "Adhesion":
                        return "badge-adhesion";
                    case "Radiation":
                        return "badge-radiation";
                    case "omgd":
                        return "badge-omgd";
                }
            } else if (scope === "text") {
                switch (type) {
                    case "Due":
                        return "Due";
                    case "new_pro_member":
                        return "Nouveau membre Pro";
                    case "Election":
                        return "Election";
                    case "transfer":
                        return "Transfert";
                    case "new_member":
                        return "Nouveau membre";
                    case "Nomination":
                        return "Nomination";
                    case "addresses":
                        return "Adresses";
                    case "Reinstatement":
                        return "Reinstatement";
                    case "Admission":
                        return "Admission";
                    case "Penalty":
                        return "Penalty";
                    case "Promotion":
                        return "Promotion";
                    case "Renewal":
                        return "Renewal";
                    case "Adhesion":
                        return "Adhésion";
                    case "Radiation":
                        return "Radiation";
                    case "omgd":
                        return "OMGD";
                }
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];
            this.getDues();
        },
        queryChanged(query: string) {
            this.dueSearch = query;
            this.getDues();
        },
    },
});
